import axios from "../config/axios";

import { setting } from "../redux/settings/setting.action";

export const getSiteSetting = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/adminApi/getSiteSetting`,
    });

    setting(respData.data.result,dispatch);
    
    return {
      status: true,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: false,
    };
  }
};

export const updateSiteDetail = async (data) => {
  try {
    let respData = await axios({
      method: "put",
      url: `/adminApi/updateSiteDetails`,
      data,
    });
    return {
      status: true,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: false,
      message: err.response.data.message,
      error: err.response.data.error
    };
  }
};

export const updateMailConfig = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `/adminApi/updateMailConfig`,
      data,
    });
    return {
      status: true,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: false,
      message: err.response.data.message,
      error: err.response.data.error
    };
  }
};

export const getMailConfig = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/adminApi/getMailConfig`,
      data,
    });
    return {
      status: true,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: false,
    };
  }
};