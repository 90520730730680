import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilDrop,
  cilSpeedometer,
  cilMoney,
  cilList,
  cilSpeech,
  cilSettings,
  cilEnvelopeClosed,
  cilUserFemale,
  cilTransfer,
  cilUserPlus,
} from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";

const _nav = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: "User Management",
    icon: <CIcon icon={cilUserFemale} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Users",
        to: "/users",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Triangular Arbitrage",
    icon: <CIcon icon={cilUserFemale} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Currency",
        to: "/currency",
        icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Combination",
        to: "/combination",
        icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Bot",
        to: "/bot",
        icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Opportunity",
        to: "/opportunity",
        icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Open - Opportunities",
        to: "/open-opportunity",
        icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Order",
        to: "/order",
        icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Trade",
        to: "/trade",
        icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Direct Arbitrage",
    icon: <CIcon icon={cilUserFemale} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Currency",
        to: "/direct-currency",
        icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Pairs",
        to: "/direct-pair",
        icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Bot",
        to: "/direct-bot",
        icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Opportunity",
        to: "/direct-opportunity",
        icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Open - Opportunities",
        to: "/direct-open-opportunity",
        icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Order",
        to: "/direct-order",
        icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Trade",
        to: "/direct-trade",
        icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
      },
    ],
  },
  {
    component: CNavItem,
    name: "Price Conversion",
    to: "/Price-Conversion",
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: "News Letter",
  //   to: "/news-letter",
  //   icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
  // },
  {
    component: CNavItem,
    name: "Contact Us",
    to: "/contact-us",
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: "Templates & CMS",
    icon: <CIcon icon={cilEnvelopeClosed} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Email Templates",
        to: "/email-templates",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
      // {
      //   component: CNavItem,
      //   name: "CMS Pages",
      //   to: "/cms",
      //   icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      // },
    ],
  },
  {
    component: CNavGroup,
    name: "FAQ",
    icon: <CIcon icon={cilSpeech} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Category",
        to: "/faq-category",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "FAQ",
        to: "/faq",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
    ],
  },
   {
    component: CNavGroup,
    name: "Support",
    icon: <CIcon icon={cilSpeech} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Category",
        to: "/support-category",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Tickets",
        to: "/support-tickets",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
    ],
  }, 
  {
    component: CNavGroup,
    name: "Admin Management",
    to: "/sub-admin",
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Sub Admin",
        to: "/sub-admin",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Modules",
        to: "/modules",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Sub Modules",
        to: "/sub-modules",
        icon: <CIcon icon={cilList} customClassName="nav-icon" />,
      },
    ],
  },
  {
    component: CNavItem,
    name: "Site Settings",
    to: "/site-settings",
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
  /* {
    component: CNavItem,
    name: "PassBook",
    to: "/passbook",
    icon: <CIcon icon={cilTransfer} customClassName="nav-icon" />,
  }, */
];

export default _nav;
