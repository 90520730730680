//import config
import axios from "../config/axios";
import config from "../config";
import { handleResp } from "../config/axios";
import { ROLE_DATA } from "../redux/role/type";
import { role } from "../redux/role/role.action";
export const loginApi = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.API_URL}/adminApi/login`,
      data,
    });
    return handleResp(respData, "success");
  } catch (err) {
    return handleResp(err, "error");
  }
};

export const forgotPasswordApi = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `adminApi/forgotPassword`,
      data,
    });
    return {
      status: true,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const resetPasswordApi = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `adminApi/resetPassword`,
      data,
    });
    return {
      status: true,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: false,
      message: err.response.data.message,
      error: err.response.data.errors,
    };
  }
};

export const getGeoInfoData = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.getGeoInfo}`,
    });

    return {
      success: true,
      result: respData.data,
    };
  } catch (err) {
    return {
      success: false,
      error: err.response.data.errors,
    };
  }
};

export const getAdminData = async (dispatch) => {
  try {
    let respData = await axios({
      method: "get",
      url: `adminApi/getadmindetail`,
    });

    role(
      {
        role: respData.data.result.role,
        restriction: respData.data.result.restriction,
      },
      dispatch
    );
  } catch (err) {
    return handleResp(err, "error");
  }
};

export const getPath = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `/adminApi/getpath`,
      params: data,
    });
    return handleResp(respData, "success");
  } catch (err) {
    return handleResp(err, "error");
  }
};