let key = {};
const mode = process.env.REACT_APP_EXEC;
if (process.env.REACT_APP_MODE === "demo") {
  console.log("Set Demo Config");
  const API_URL = "https://dopaminearbitribebotapi.wealwin.com:2528";

  key = {
    API_URL: `${API_URL}`,
    FRONT_URL: "https://dopaminearbitragebot.wealwin.com",
    ADMIN_URL: "https://dopaminearbitragebotcontrols.wealwin.com",
    getGeoInfo: "https://ipapi.co/json/",
    mode,
    BITMART_API_URL: '',
  };
} else {
  console.log("Set Development Config");
  const API_URL = "http://localhost";

  key = {
    API_URL: `${API_URL}:2077`,
    ADMIN_URL: "http://localhost:3023",
    getGeoInfo: "https://ipapi.co/json/",
    mode,
    BITMART_API_URL: `${API_URL}:2078`,
  };
}

export default key;
